(function ($) {
  'use strict';

  let cookieName;
  let leadSourceFieldName;
  let referringUrlFieldName;
  let campaignFieldName;

  let parseLeadSource = function (context) {
    let urlData = {
      'currentUrl': window.location.href,
      'referrer': document.referrer,
    };

    $.post('/lead_source', urlData)
        .done(function (data) {
          setReferrerCookie(data);
          populateFields(data, context);
        });
  };

  let populateFields = function (leadSource, context) {
    let $leadSourceInput = $('input[name="' + leadSourceFieldName + '"]', context);
    let $referringURLInput = $('input[name="' + referringUrlFieldName + '"]', context);
    let $campaignInput = $('input[name="' + campaignFieldName + '"]', context);

    if ($leadSourceInput.length) {
      let leadMediumSource = leadSource.medium
          ? leadSource.medium + ' - ' + leadSource.source
          : 'Unknown Lead Source';

      $leadSourceInput.val(leadMediumSource);
    }

    if ($referringURLInput.length) {
      $referringURLInput.val(leadSource.referringUrl);
    }

    if ($campaignInput.length) {
      $campaignInput.val(leadSource.campaign);
    }
  };

  function getReferrerCookie() {
    if (typeof Cookies === 'undefined') {
      return false;
    }

    let cookie = Cookies.get(cookieName);

    if (cookie === undefined || cookie === '') {
      return false;
    }

    return JSON.parse(cookie);
  }

  function setReferrerCookie(referrer) {
    if (typeof Cookies === 'undefined') {
      console.log('Top Floor Lead Source: js-cookie library is not loaded.');

      return;
    }

    Cookies.set(cookieName, JSON.stringify(referrer), {
      expires: 1,
      path: '/',
    });
  }

  Drupal.behaviors.topfloorLeadSource = {
    attach: function (context, settings) {
      cookieName = Drupal.settings.topfloorLeadSource.cookieName;
      leadSourceFieldName = Drupal.settings.topfloorLeadSource.leadSource;
      referringUrlFieldName = Drupal.settings.topfloorLeadSource.referringUrl;
      campaignFieldName = Drupal.settings.topfloorLeadSource.campaign;

      let leadSource = getReferrerCookie();

      if (!leadSource) {
        parseLeadSource(context);
      }
      else {
        populateFields(leadSource, context);
      }
    }
  };
})(jQuery);
